<template>
  <div>
    <jy-dialog
      type="form"
      title="轨迹回放"
      :visible.sync="dialogVisible"
      width="90%"
      appendToBody
    >
      <div class="track-wrapper">
        <div class="trackReplay_box">
          <jy-query ref="checkForm" :model="diaform">
            <jy-query-item
              v-model="diaform.type"
              label="选择车辆"
              prop="vehicleNosStr"
            >
              <el-input disabled :value="diaform.vehicleNosStr"></el-input>
            </jy-query-item>

            <jy-query-item label="设备类型" prop="type">
              <el-select v-model="diaform.type">
                <el-option
                  v-for="op in deviceTypeList"
                  :key="op.value"
                  :label="op.label"
                  :value="op.value"
                ></el-option>
              </el-select>
            </jy-query-item>

            <jy-query-item label="回放速度" prop="multiple">
              <el-select v-model="diaform.multiple" placeholder="请选择">
                <el-option
                  v-for="item in speedList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </jy-query-item>
          </jy-query>

          <jy-query style="margin-top: 10px">
            <jy-query-item label="回放时间" :span="1.6" prop="time">
              <el-date-picker
                v-model="diaform.time"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </jy-query-item>

            <template #rightCol>
              <el-button type="primary" @click="toStart" :disabled="isToStart"
                >回放</el-button
              >

              <el-button
                type="primary"
                @click="toPause"
                :disabled="isToPauseStop"
                >暂停</el-button
              >

              <el-button
                type="primary"
                @click="toStop"
                :disabled="isToPauseStop"
                >停止</el-button
              >
            </template>
          </jy-query>
          <div class="track">
            <div
              class="map-wrapper"
              ref="trackContainer"
              style="overflow: inherit !important"
            ></div>
            <div ref="legend">
              <div class="pre"><i class="el-icon-minus"></i>规定路线</div>
              <div class="rel"><i class="el-icon-minus"></i>实际轨迹</div>
            </div>
          </div>
          <div class="jy_table">
            <jy-table
              tooltip-effect="dark"
              :data="dataList"
              :height="tableHeigth"
            >
              <jy-table-column
                prop="vin"
                label="VIN号"
                min-width="120"
              ></jy-table-column>
              <jy-table-column
                prop="lng"
                label="经度"
                min-width="120"
              ></jy-table-column>
              <jy-table-column
                prop="lat"
                label="纬度"
                min-width="120"
              ></jy-table-column>
              <jy-table-column
                prop="spd"
                label="车速(km/h)"
                min-width="120"
              ></jy-table-column>
              <jy-table-column
                prop="address"
                label="位置"
                min-width="120"
              ></jy-table-column>
              <jy-table-column
                prop="time"
                label="上报时间"
                min-width="120"
              ></jy-table-column>
            </jy-table>
            <div>
              <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
              ></jy-pagination>
            </div>
          </div>
        </div>
      </div>
    </jy-dialog>
  </div>
</template>

<script>
import { deviceType } from "@/common/js/wordbook.js";
import gcoord from "gcoord";
import LuShu from "@/common/js/BMapLib/LuShu.js";
import startImg from "@/assets/startIcon.png";
import endImg from "@/assets/endIcon.png";

export default {
  methods: {
    initMap() {
      this.trackMap = new BMap.Map(this.$refs.trackContainer);
      this.trackMap.enableScrollWheelZoom();
      this.trackMap.centerAndZoom(new BMap.Point(116.404, 39.915), 13);
      this.oncheck();
    },
    clickVehicle() {
      this.$refs.vehicle.init();
    },
    init(v) {
      this.$nextTick(() => {
        this.vehicle = v;
        this.dialogVisible = true;
        this.diaform.vehicleNosStr = v.vehicleNo;
        this.selectVehicles.push(v);
        setTimeout(() => {
          this.initMap();
        }, 0);
      });
    },
    oncheck() {
      this.trackMap && this.trackMap.clearOverlays();
      this.selectVehicles.forEach((v) => {
        this.addLuShu(v);
        this.addPreSet(v);
      });
      this.addSiteMarkers();
      const routeIds = this.selectVehicles.map((v) => v.routeId);
      routeIds.forEach((v) => this.addSiteMarkers(v));
    },
    addSiteMarkers(routeId) {
      this.$http.post("/site/info/queryByRouteId", { routeId }).then((res) => {
        res.detail.forEach((site) => {
          const { routeSite, siteInfo } = site;
          const [lng, lat] = gcoord.transform(
            [siteInfo.lng, siteInfo.lat],
            gcoord.WGS84,
            gcoord.BD09
          );
          const marker = new BMap.Marker(new BMap.Point(lng, lat));
          const labelText =
            siteInfo.cName +
            "(" +
            (routeSite.goType === 1 ? "上行" : "下行") +
            ")";
          let label = new BMap.Label(labelText, {
            offset: new BMap.Size(30, -11),
          });
          marker.setLabel(label);

          this.trackMap.addOverlay(marker);
        });
      });
    },
    async addLuShu(vehicle) {
      const { detail: deviceList } = await this.$http.post(
        "/base/device/queryByVehicleId",
        {
          vehicleId: vehicle.vehicleId,
        }
      );
      console.log(deviceList);
      const deviceId = this.getDeviceIdByList(deviceList);
      if (deviceId) {
        const { detail: gpsData } = await this.$http.post(
          "/waybillActual/getGpsDataByDevice",
          {
            deviceNo: deviceId,
            beginTime: this.diaform.time[0],
            endTime: this.diaform.time[1],
          }
        );
        console.log(gpsData);
        if (Array.isArray(gpsData)) {
          let arrPosi = [],
            landmarkPois = [];
          this.gpsData = gpsData;
          gpsData.forEach((v) => {
            const [lng, lat] = gcoord.transform(
              [v.lng, v.lat],
              gcoord.WGS84,
              gcoord.BD09
            );
            arrPosi.push(new BMap.Point(lng, lat));
            landmarkPois.push({
              lng: lng,
              lat: lat,
              html: `<div style="text-align:left;">
                                        <span>时间：${this.$util.fixedMacTime(
                                          v.time
                                        )}</span>
                                        <br />
                                        <span>速度：${v.spd}</span>
                                        <br />
                                        <span>行驶里程：${v.mileage}</span>
                                        <br />
                                        <span>经纬度：经度${lng}，纬度${lat}</span>
                                        <br />
                                        <span>方向角：${v.dir}</span>
                                    </div>`,
            });
          });
          // 添加首尾标记
          this.addStartEndMarker(arrPosi[0], arrPosi[arrPosi.length - 1]);

          this.trackMap.centerAndZoom(arrPosi[0], 16);
          // 添加polyline
          const polyline = new BMap.Polyline(arrPosi, {
            strokeColor: "blue", //设置颜色
            strokeWeight: 5, //宽度
            strokeOpacity: 0.5, //透明度
          });
          this.trackMap.addOverlay(polyline);
          // 添加路书
          const lushu = new LuShu(this.trackMap, arrPosi, {
            defaultContent: "", //"从天安门到百度大厦"
            autoView: false, //是否开启自动视野调整，如果开启那么路书在运动过程中会根据视野自动调整
            icon: new BMap.Icon(
              "https://developer.baidu.com/map/jsdemo/img/car.png",
              new BMap.Size(52, 26),
              { anchor: new BMap.Size(27, 13) }
            ),
            speed: this.diaform.multiple, //运行速度
            enableRotation: true, //是否设置marker随着道路的走向进行旋转
            landmarkPois: landmarkPois,
            showInfoWindow: true,
          });
          this.lushuInsArray.push(lushu);
          this.isToStart = false;
          // 表格数据
          this.getTableList();
          this.total = gpsData.length;
        }
      }
    },
    async addPreSet(vehicle) {
      const { detail: gpsData } = await this.$http.post(
        "/route/info/queryRoutePreSetSite",
        {
          routeId: vehicle.routeId,
        }
      );
      if (Array.isArray(gpsData)) {
        let arrPosi = [];
        gpsData.forEach((v) => {
          const [lng, lat] = gcoord.transform(
            [v.lng, v.lat],
            gcoord.WGS84,
            gcoord.BD09
          );
          arrPosi.push(new BMap.Point(lng, lat));
        });
        // 添加首尾标记
        this.addStartEndMarker(arrPosi[0], arrPosi[arrPosi.length - 1]);

        this.trackMap.centerAndZoom(arrPosi[0], 16);
        // 添加polyline
        const polyline = new BMap.Polyline(arrPosi, {
          strokeColor: "red", //设置颜色
          strokeWeight: 5, //宽度
          strokeOpacity: 0.5, //透明度
        });
        this.trackMap.addOverlay(polyline);
      }
    },
    getDeviceIdByList(list) {
      if (Array.isArray(list)) {
        const newList = list.filter((v) => v.dType == this.diaform.type);
        return newList[0] ? newList[0].deviceId : "";
      }
    },
    addStartEndMarker(startPos, endPos) {
      if (startPos && endPos) {
        let startPoint = new window.BMap.Point(startPos.lng, startPos.lat);
        let endPoint = new window.BMap.Point(endPos.lng, endPos.lat);
        var startIcon = new BMap.Icon(startImg, new BMap.Size(50, 50));
        var endIcon = new BMap.Icon(endImg, new BMap.Size(50, 50));
        let marker = new BMap.Marker(startPoint, {
          icon: startIcon,
        });
        let endmarker = new BMap.Marker(endPoint, {
          icon: endIcon,
        });
        this.trackMap.addOverlay(marker);
        this.trackMap.addOverlay(endmarker);
      }
    },
    resetForm(formName) {
      this.trackMap && this.trackMap.clearOverlays();
      this.$refs[formName].resetFields();
      this.diaform.time = [
        new Date().Format("yyyy-MM-dd ") + "09:37:00",
        new Date().Format("yyyy-MM-dd ") + "17:37:00",
      ];
    },
    toStart() {
      this.isToStart = true;
      this.isToPauseStop = false;
      this.lushuInsArray.forEach((ins) => {
        ins._opts.speed = this.diaform.multiple; // 修改速度
        ins.start();
      });
    },
    toPause() {
      this.isToStart = false;
      this.isToPauseStop = true;
      this.lushuInsArray.forEach((ins) => {
        ins.pause();
      });
    },
    toStop() {
      this.isToStart = false;
      this.isToPauseStop = true;
      this.lushuInsArray.forEach((ins) => {
        ins.stop();
      });
    },
    // 表格数据分页，转换经纬度
    getTableList() {
      // 分页
      let start = (this.pageIndex - 1) * this.pageSize;
      let end = this.pageIndex * this.pageSize;
      let list = this.gpsData.slice(start, end);
      list.forEach((item) => {
        let time = "";
        time =
          "20" +
          item.time.slice(0, 2) +
          "-" +
          item.time.slice(2, 4) +
          "-" +
          item.time.slice(4, 6) +
          " " +
          item.time.slice(6, 8) +
          ":" +
          item.time.slice(8, 10) +
          ":" +
          item.time.slice(10, 12);
        item.time = time
      });
      this.dataList = list;

      var gc = new BMap.Geocoder();
      for (let i = 0; i < list.length; i++) {
        var p0 = list[i].lng;
        var p1 = list[i].lat;
        var point = new BMap.Point(p0, p1);

        //   获取详细位置
        var _that = this;
        gc.getLocation(point, function (rs) {
          _that.dataList[i].address = rs.address;
          _that.$set(_that.dataList, i, _that.dataList[i]);
        });
      }
    },
    // 分页
    handleSizeChange(val) {
      // 每页条数
      this.pageSize = val;
      this.getTableList();
    },
    handleCurrentChange(val) {
      // 当前页条数
      this.pageIndex = val;
      this.getTableList();
    },
  },
  created() {
    this.deviceTypeList = deviceType();
    this.diaform.time = [
      new Date().Format("yyyy-MM-dd ") + "09:37:00",
      new Date().Format("yyyy-MM-dd ") + "17:37:00",
    ];
  },
  data() {
    return {
      diaform: {
        time: [],
        type: 4,
        vehicleNosStr: "",
        multiple: 10,
      },
      vehicle: "",
      dataList: [],
      gpsData: [],
      selectVehicles: [],
      trackMap: null,
      lushuInsArray: [],
      dialogVisible: false,
      deviceTypeList: [],
      isToStart: true,
      isToPauseStop: true,
      tableHeigth: 200,
      speedList: [
        {
          value: 10,
          label: "1X",
        },
        {
          value: 30,
          label: "3X",
        },
        {
          value: 50,
          label: "5X",
        },
        {
          value: 100,
          label: "10X",
        },
        // {
        //     value: 300,
        //     label: "300X"
        // }
      ],
      // 分页
      pageIndex: 1,
      total: 100,
      pageSize: 10,
    };
  },
};
</script>

<style lang="scss" scoped>
.track-wrapper {
  height: 100%;
  .trackReplay_box {
    height: 100%;
    display: flex;
    flex-direction: column;
    .track {
      height: 600px;
      display: flex;
      .map-wrapper {
        flex: 1;
        height: 100%;
      }
      .pre {
        [class^="el-icon-"],
        [class*=" el-icon-"] {
          color: red;
          width: 30px;
          font-size: 24px;
          margin-left: 20px;
          margin-top: 300px;
        }
      }
      .rel {
        [class^="el-icon-"],
        [class*=" el-icon-"] {
          color: blue;
          width: 30px;
          font-size: 24px;
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
